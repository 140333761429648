//----------------------------------------------// In-built Imports // -------------------------------------------------

import React, { useCallback, useEffect, useRef, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------

import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import { initializeSSO } from '../../services/SsoService';
import DocsPopup from '../ViewContact/NotesAttachments/DocsPopup'
// import LegacySkills from './Components/LegacySkills';
// import LegacyAttributes from './Components/LegacyAttributes';
import {
  getIncludeLegacyValues,
  getNavItems, getUpdatedAt, getActivityLogTabNames, getRootDefaultValues,
} from './utils';
import EmailView from '../ViewContact/ProfileView/EmailView';
import VideoView from '../ViewContact/ProfileView/VideoView';
import HireLogicLogo from '../../assets/images/hirelogic_logo.png';
import PhoneView from '../ViewContact/ProfileView/PhoneView';
import ScreeningNotesAndCompensation from "./Components/ScreeningNotesAndCompensation";
import TabLayoutWithHeader from '../TabLayoutWithHeader';
import GeneralInfo from './Components/GeneralInfo';
import MilestoneDates from "./Components/MilestoneDates/MilestoneDates";
import './index.scss'
import { cleanupFormValues, INSIDE_TRACK, LinkedInRenderer } from "../../utils/common";
import References from "./Components/References";
import StrengthsAndPotentialIssues from "./Components/StrengthsAndPotentialIssues";
import Recommendations from './Components/Recommendations';
import MessageLoader from "../common/MessageLoader/MessageLoader";
import CandidateSnapshot from './Components/CandidateSnapshot';
import Attributes from './Components/Attributes';
import EmploymentHistory from "./Components/EmploymentHistory";
import ActivityHistories from './Components/GeneralInfo/Components/ActivityHistory/ActivityHistories';
import Skills from "./Components/Skills";
import ClientSuiteFeedback from "./Components/ClientSuiteFeedback";
import CandidateSuiteFeedback from "./Components/CandidateSuiteFeedback";
import ArenaFeedback from "./Components/ArenaFeedback";
import { ACTIVITIES, CANDIDATE, CONTACT, GET, POST, ERROR, SUCCESS, WARNING, PATCH, HIRELOGIC_STAGES, PUT, ACTIVITIES_TABDATA } from '../../services/constantService';
import { acquireLockApi, candidateContactDataApi, candidateDataApi, contactDataApi, hlDataApi, releaseLockApi, renewLockApi, searchDataApi, activityInfoApi } from '../../services/ApiService';
import { unableMessage, VALIDATION_MESSAGE, successMessage, notFoundMessage } from '../../services/MessageService';
import { LogAnActivityPopup } from "./Components/LogAnActivityPopup";
import ConfirmationPopup from "../ConfirmationPopup";
import HireLogic from '../../Containers/Commons/HireLogicPopup';
import { getUserSelector } from '../../selectors';
import { RELOAD_ALL_CANDIDATE_GRID } from '../../types';
import { contactActions } from '../../store/contactSlice';
import EmploymentHistoryConfirmationDialog from '../../Containers/Commons/EmploymentHistoryConfirmationDialog';
import Invites from './Components/Invites';
import { SPECIAL_CHARACTERS_REPLACE_REGEX, REPLACE_SPACE_REGEX } from '../../services/constantService';
import { validateStartYearEndYear } from '../../utils/date';

function ActivityLog() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id, path, searchId, contactId, currentTabs } = useParams();
  const [readOnly, setReadOnly] = useState(false);
  const userData = useSelector(getUserSelector);
  const history = useHistory();
  const location = useLocation();
  const [activityInf, setActivityInf] = useState(null)
  const [hireLogic, setHireLogic] = useState(false)
  const [activityCurrentValues, setActivityCurrentValues] = useState({})
  const [search, setSearch] = useState({})
  const [currentValues, setCurrentValues] = useState(null)
  const [loading, setLoading] = useState(false)
  const [video, setVideo] = useState(null);
  const [statusMessage, setStatusMessage] = useState('')
  const { reset, getValues, /* setValue, handleSubmit */ } = useForm()
  // const { dirty } = formState
  const [showActivityHistory, setShowActivityHistory] = useState(false);
  const [showDocs, setShowDocs] = useState(false)
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)
  const [isRichTextSaved, setRichTextSaved] = useState(false)
  const [showConfirmBack, setShowConfirmBack] = useState(false)
  const [workspace, setWorkspace] = useState([])
  const [options, setOptions] = useState([{}])
  const [currentTab, setCurrentTab] = useState(0);
  const [showJobTitleChangedPopup, setShowJobTitleChangedPopup] = useState(false)
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryEndYearDate] = useState(null);
  const [isUpdateEmploymentHistory, setEmploymentHistory] = useState(false)
  const [skipCheckJobTitle, setSkipJobTitle] = useState(false);
  const [handleSaveArgs, setHandleSaveArgs] = useState({});
  const [parentUpdatedAt, setParentUpdatedAt] = useState(null);
  let [descriptionPopup] = useState(false)
  const logAnActivityRef = useRef()
  const activityPopupRef = useRef()
  // const generalInfoRef = useRef()
  

  const arenaSearch = search?.type === INSIDE_TRACK;
  const includeLegacyTabs = getIncludeLegacyValues(search)
  const tabRefArray = {
    'GENERAL_INFO_TAB': useRef(),
    'MILESTON_DATES_TAB': useRef(),
    'SCREENING_NOTES_TAB': useRef(),
    'EMPLOYMENT_HISTORY_TAB': useRef(),
    'EXPERIENCE_TAB': useRef(),
    'STRENGTH_AND_ISSUE_TAB': useRef(),
    'INVITES_TAB': useRef(),
    'LEADERSHIP_COMPETENCIES_TAB': useRef(),
    'CANDIDATE_SNAPSHOT_TAB': useRef(),
    'RECOMMENDATION_TAB': useRef(),
    "CLIENTSUITE_FEEDBACK": useRef(),
    "CANDIDATESUITE_FEEDBACK": useRef(),
    "REFERENCES_TAB": useRef(),
    "ARENA_FEEDBACK_TAB": useRef(),
    "LEGASY_EXPERIENCE_TAB": useRef(),
    "LEGASY_ATTRIBUTES_TAB": useRef(),
  }
  const locationState = location?.state?.contactId;
  const dispatch = useDispatch()
  let retryCount = 0;
  const halfAnHour = 30 * 60 * 1000;
  let editLockRenewTimer;
  const NAV_ITEMS = getNavItems(contactId, arenaSearch, includeLegacyTabs);
  const newNavItems = NAV_ITEMS.map((item) => {
    item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and');
    return item.toLowerCase().replace(REPLACE_SPACE_REGEX, '-')
  })
  let currentTabIndex;
  let tabName = window.location.pathname.split('/')[3];
  const valueReadOnly = window.location.href.includes('read-only');
  currentTabIndex = newNavItems.findIndex(item => item === currentTabs);
  if (currentTabIndex === -1 && contactId) {
    if (!valueReadOnly) {
      currentTabIndex = history.push(`/contacts/${contactId}/log-an-activity/general-info`, location.state);
    }
    else {
      currentTabIndex = history.push(`/contacts/${contactId}/log-an-activity/read-only/general-info`, location.state);
    }
  }

  if (currentTabIndex === -1 && searchId) {
    if (!valueReadOnly) {
      currentTabIndex = history.push(`/searches/${searchId}/${tabName}/${id}/log-an-activity/general-info`, location.state)
    }
    else {
      currentTabIndex = history.push(`/searches/${searchId}/${tabName}/${id}/log-an-activity/read-only/general-info`, locationState)
    }
  }
  const newTabName = getActivityLogTabNames(contactId, arenaSearch, includeLegacyTabs, currentTabIndex || 0)
  const [currentTabName, setCurrentTabName] = useState(newTabName);
  // setCurrentTab(newTabName)

  const getActivityLogInfo = useCallback(async (hideLoader) => {
    !hideLoader && setLoading(true);
    let type, param_id, sub_route;
    //Activity Log for contacts
    if (contactId) {
      type = CONTACT
      param_id = contactId
    } else if (path === "candidates" || path === "all-candidates" || path === "client-suite-report" || path === "my-linkedin-candidates") {
      type = CANDIDATE
      param_id = id
    }
    sub_route = `${ACTIVITIES_TABDATA}?tabName=${'ROOT_INFO'}&type=${type}`;
    const { status, data } = await activityInfoApi(GET, param_id, sub_route);
    // const { status, data } = await candidateContactDataApi(GET, param_id, type, {}, ACTIVITY);
    if (status && status === 200) {
      // getVideo();
      data.sfpa && data.sfpa.attributesV1 && Object.prototype.hasOwnProperty.call(data.sfpa, "attributesV1") && (data.sfpa.attributes = data.sfpa.attributesV1)
      data.candidate && data.candidate.attributesV1 && Object.prototype.hasOwnProperty.call(data.candidate, "attributesV1") && (data.candidate.attributes = data.candidate.attributesV1)
      setActivityInf(data);
    } else {
      const message = unableMessage("activity info", "fetch")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    !hideLoader && setLoading(false);
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    getVideo()
  }, [])

  const getLocationState = async () => {
    if (locationState) {
      return locationState;
    }
    const id = await getContactId();
    return id;
  }

  const getVideo = async () => {
    let param_id;
    if (contactId) {
      param_id = contactId
    } else {
      param_id = await getLocationState()
    }
    const sub_route = 'video'
    const { status, data } = await contactDataApi(GET, param_id, {}, sub_route);
    if (status === 200) {
      if (status === 200 && data !== null) {
        setVideo(data);
        return data;
      }
      return {};
    }
  }

  // useEffect(() => {
  //   register('current_job_start_year')
  // }, [register])

  useEffect(() => {
    if (workspace.length > 0) {
      if (workspace.length === 1) {
        setLoading(false);
        setHireLogic(false);
        handleWorkspaceSubmit();
      } else {
        setOptions(workspace);
        setHireLogic(true);
        setLoading(false);
      }
    }
  }, [workspace])

  useEffect(() => {
    const checkLock = async () => {

      if (!contactId) {
        let ids = await getContactId();
        let { status, data } = await acquireLockApi(ids);
        if ((status && status === 200 && data.message === undefined) || data.user) {
          return;
        } else {
          currentTabIndex = newNavItems.findIndex(item => item === currentTabs);
          history.replace(`/searches/${searchId}/candidates/${id}/log-an-activity/read-only/${newNavItems[currentTabIndex]}`, {
            ReadOnly: true
          });
        }
      }
      else {
        let { status, data } = await acquireLockApi(contactId);
        if ((status && status === 200 && data.message === undefined) || data.user) {
          return
        } else {
          currentTabIndex = newNavItems.findIndex(item => item === currentTabs);
          history.replace(`/contacts/${contactId}/log-an-activity/read-only/${newNavItems[currentTabIndex]}`, {
            ReadOnly: true
          });
        }
      }
    }
    checkLock()
  }, [])

  useEffect(() => {
    return () => {
      window.addEventListener("beforeunload", async () => {
        if (contactId && !history.locationState.ReadOnly) {
          editLockRenewTimer = clearInterval(editLockRenewTimer);
          return await releaseLockApi(contactId);
        }
        else {
          editLockRenewTimer = clearInterval(editLockRenewTimer);
          const newContactId = await getLocationState();
          return await releaseLockApi(newContactId);
        }
      });
      editLockRenewTimer = setInterval(renewLock, halfAnHour);
    }
  })
  const getContactId = async () => {
    try {
      const { status, data } = await candidateDataApi(GET, id);
      if (status && status === 200) {
        let contactsId = data?.candidate?.contact?.id;
        return contactsId;
      }
    }
    catch (e) {
      return null;
    }
  }

  useEffect(() => {
    try {
      (async () => {
        if (searchId) {
          const { status, data } = await searchDataApi(GET, searchId);
          if (status && status === 200) {
            setSearch(data);
          } else {
            const message = unableMessage("search info", "fetch")
            enqueueSnackbar(data?.message || message, { variant: ERROR });
          }
        }
      })()
    } catch (e) {
      console.log(e)
    }
  }, [searchId, enqueueSnackbar])

  const releaseLock = async () => {
    if (contactId) {
      await releaseLockApi(contactId);
      editLockRenewTimer = clearInterval(editLockRenewTimer);
    }
    else {
      const newContactId = await getLocationState();
      await releaseLockApi(newContactId);
      editLockRenewTimer = clearInterval(editLockRenewTimer);
    }

    window.removeEventListener('beforeunload', releaseLock, true);
  }
  const renewLock = async () => {
    if (document.hidden) {
      if (retryCount > 3) {
        clearInterval(editLockRenewTimer);
      }
      retryCount++;
    } else {
      retryCount = 0;
      if (contactId) {
        renewLockApi(contactId);
      }
      else {
        const newContactId = await getLocationState();
        renewLockApi(newContactId);
      }
    }
  }

  const uploadContactProfilePic = async (payload) => {
    setStatusMessage("Updating candidate profile pic...")
    var bodyFormData = new FormData();
    bodyFormData.append('profile', payload);
    const sub_route = 'image'
    const { status, data } = await contactDataApi(POST, activityInf.contact.id, bodyFormData, sub_route);
    if (status !== 200) {
      const message = unableMessage("upload profile pic", "candidate")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setStatusMessage("")
  }

  const autoSaveContactFields = async (comments, skipMessage, setAutoSaveLoading) => {
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true)
    }
    await updateContactInfo(comments, skipMessage)
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false)
      }, 1000);
    }
  }

  const updateContactInfo = async (contact, skipMessage = false) => {
    // !skipMessage && setLoading(true)
    let returnResult;
    let param_id;
    if (contactId) {
      param_id = contactId
    } else {
      param_id = activityInf?.contact?.id
    }
    const { status, data } = await contactDataApi(PUT, param_id, contact);
    if (status && status === 200) {
      const message = successMessage("Details", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true)
      setRichTextSaved(true)
      // await getActivityLogInfo(true);
      await tabRefArray[currentTabName]?.current?.reload(true)
      // setLoading(false)
      returnResult = true;
    } else if (status === 409) {
      setLastSaveSuccess(false)
      const message = unableMessage("details", "update")
      enqueueSnackbar(data?.message || message,
        {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
              }}
            >
              Dismiss
            </Button>
          )
        }
      );
      setLoading(false)
      returnResult = false;
    } else {
      setLastSaveSuccess(false)
      const message = unableMessage("details", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false)
      returnResult = false;
    }
    // delete contact.old;
    // let newActivityInfo = { ...activityInf, contact: { ...activityInf.contact, ...contact, updated_at: new Date().toISOString() } }
    // setActivityInf(newActivityInfo)
    // setRichTextSaved(true)
    // setCurrentValues({ ...currentValues, ...payload.contact })

    return returnResult
  }

  // const setSkillInfo = (skillData) => {
  //  delete skillData.created_at;
  //  delete skillData.candidate_id;

  //  let activityInfoWithSkills = activityInf
  //  activityInfoWithSkills.candidate.skills = activityInfoWithSkills.candidate.skills.map(skill => {
  //    if (skill.skill_id === skillData.skill_id) {
  //      return { ...skill, ...skillData }
  //    } else return { ...skill }
  //  })
  //  setActivityInf(activityInfoWithSkills);
  // }

  const updateCandidateInfo = async (skills, skipMessage, updateType, item) => {
    let payload;
    if (updateType === 'rating') {
      payload = { ...skills, skill_id: item }
      delete payload.id
      delete payload.updated_at
    } else if (updateType === 'skill_approval') {
      payload = { 'skill_approval': skills }
    }
    else {
      let skill = skills[Object.keys(skills)[0]];
      if (updateType === 'recruiter') {
        payload = {
          ...skills,
          skill_id: item,
          recruiter_comments: skill
        }
      } else {
        payload = {
          ...skills,
          skill_id: item,
          partner_comments: skill
        }
      }
    }

    const param_id = id
    const type = CANDIDATE
    const sub_route = `${ACTIVITIES}`

    const { status, data } = await candidateContactDataApi(PATCH, param_id, type, payload, sub_route);
    if (status && status === 200) {
      const message = successMessage("Details", VALIDATION_MESSAGE.updated_message)
      skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true)
      setRichTextSaved(true)
      // await getActivityLogInfo(skipMessage);
      // setSkillInfo(data.result)
      return data;
    } else if (status === 409) {
      setLastSaveSuccess(false)
      const message = unableMessage("details", "update")
      enqueueSnackbar(data?.message || message,
        {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
              }}
            >
              Dismiss
            </Button>
          )
        }
      );
      return false;
    } else {
      setLastSaveSuccess(false)
      const message = unableMessage("details", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
      return false;
    }
  }

  const acquire = async () => {
    setLoading(true);
    let id
    if (contactId) {
      id = contactId
    } else {
      id = await getLocationState()
    }
    let { status, data } = await acquireLockApi(id, { 'detachedLockBroken': true })
    if (status && status === 200) {
      setLoading(false);
      return true;
    } else {
      enqueueSnackbar(data.message, {
        persist: true,
        variant: ERROR,
        // eslint-disable-next-line react/display-name
        action: key => (
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={() => {
              closeSnackbar(key)
            }}
          >
            Dismiss
          </Button>
        )
      });
    }
    setLoading(false);
  }

  // const handleSave = async (saveAndClose, bypassValidation = false, skipMessage, skipBack, tabValue) => {
  //   const data = await acquire();
  //   if (data) {
  //     // let formValues = Object.assign(currentValues, getValues({ nest: true }))
  //     const result = getValues({ nest: true });
  //     if (result.attributes) {
  //       result.attributes.map(r => currentValues.attributes.map(c => {
  //         if (r.search_attribute_id === c.search_attribute_id) {
  //           if (!r.comments) {
  //             r.comments = c.comments
  //           }
  //         }
  //       }))
  //     }
  //     const formValues = Object.assign(currentValues, result)
  //     if (bypassValidation || await formSchema.isValid(formValues)) {
  //       let validateWorkEmail = true;
  //       let validatePrivateEmail = true;
  //       validateWorkEmail = validateEmail(formValues.work_email)
  //       validatePrivateEmail = validateEmail(formValues.private_email)
  //       if (!validateWorkEmail || !validatePrivateEmail) {
  //         const message = validateMessage(`valid email`, "enter")
  //         enqueueSnackbar(message, { variant: ERROR });
  //         handleSubmit(null);
  //         return false;
  //       }

  //       const oldContact = activityInf.contact
  //       formValues.job_history = formValues.job_history.filter(item => item?.company)
  //       formValues.current_job_start_year = oldContact.current_job_start_year
  //       if ((oldContact.current_job_title !== formValues.current_job_title || oldContact.company.id !== formValues.current_company?.id) && !skipCheckJobTitle) {
  //         setShowJobTitleChangedPopup(true)
  //         setHandleSaveArgs({ saveAndClose, bypassValidation, skipMessage, skipBack, tabValue })
  //         releaseLock();
  //         return false
  //       }
  //       if (skipCheckJobTitle) {
  //         setSkipJobTitle(false)
  //         if (isUpdateEmployementHistory) {
  //           let newJobHistory = [{
  //             company: oldContact.company,
  //             title: oldContact.current_job_title, start_year: oldContact.current_job_start_year, end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear()
  //           }]
  //           formValues.job_history = [...newJobHistory, ...formValues.job_history]
  //           setEmployementHistory(false)
  //         }
  //         formValues.current_job_start_year = new Date().getFullYear()
  //         setValue('current_job_start_year', new Date().getFullYear())
  //         setHandleSaveArgs({})
  //       }

  //       const payload = await getActivityPayload(formValues, activityInf, userData);
  //       if (activityInf.contact.updated_at) {
  //         payload.contact.updated_at = getUpdatedAt(activityInf.contact.updated_at, activityCurrentValues.contact.updated_at)
  //       }
  //       payload.activity = null;
  //       if (!skipMessage) {
  //         setStatusMessage('Updating details')
  //         // setLoading(true)
  //       }
  //       let type, param_id;
  //       if (contactId) {
  //         type = CONTACT
  //         param_id = contactId
  //         delete payload.candidate;
  //       } else {
  //         type = CANDIDATE
  //         param_id = id
  //         payload.candidate.updated_at = getUpdatedAt(currentValues.updated_at, activityCurrentValues.candidate.updated_at)
  //         delete payload.candidate.stage
  //         delete payload.candidate.reason
  //       }
  //       setLoading(true);
  //       // Delete Extra payload to reduce api load on tab change
  //       delete payload.activities
  //       delete payload.sfpa
  //       if (tabValue) {
  //         delete payload.contact.interviews
  //         delete payload.contact.activities
  //         !contactId && tabValue !== 5 && delete payload.contact.issues
  //         delete payload.contact.sfpa_attributes
  //         delete payload.contact.skill_ratings
  //         delete payload.contact.skills
  //         delete payload.contact.published_bio
  //         delete payload.contact.personal_profile
  //         delete payload.contact.career_snapshot_profile
  //         delete payload.contact.screening_notes
  //         !contactId && tabValue !== 5 && delete payload.contact.strengths
  //         if (tabValue != 7) {
  //           delete payload.candidate?.attributes
  //           delete payload.contact?.attributes
  //         }
  //         if (tabValue != 1) {
  //           delete payload.candidate?.client_interviews
  //         }
  //         if (tabValue === 2 || tabValue === 3 || tabValue === 5 || tabValue === 8) {
  //           delete payload.candidate
  //         }
  //         if (!contactId && tabValue === 1) {
  //           delete payload.contact
  //         }
  //       }
  //       const { status, data } = await candidateContactDataApi(POST, param_id, type, payload, ACTIVITIES);
  //       setStatusMessage("");

  //       if (status && (status === 201 || status === 200)) {
  //         setLoading(false);
  //         setLastSaveSuccess(true)
  //         setRichTextSaved(false)
  //         if (!skipMessage) {
  //           const message = successMessage("updated", "Details")
  //           enqueueSnackbar(message, { variant: SUCCESS })
  //         }
  //         if (formValues.contact_profile_pic) {
  //           await uploadContactProfilePic(formValues.contact_profile_pic);
  //         }
  //         if (saveAndClose) {
  //           // let isActivitySaved = true;
  //           // if (activityPopupRef.current.isDirty()) {
  //           //   await getActivityLogInfo(skipMessage);
  //           //   isActivitySaved = await activityPopupRef.current.saveActivity()
  //           // }
  //           if (!skipBack/*  && isActivitySaved */) {
  //             releaseLock();
  //             goBack()
  //           } else {
  //             await getActivityLogInfo();
  //           }
  //         } else {
  //           await getActivityLogInfo(true);
  //         }
  //         return true;
  //       } else if (status === 409) {
  //         const message = unableMessage("details", "save")
  //         enqueueSnackbar(data?.message || message,
  //           {
  //             persist: true,
  //             variant: ERROR,
  //             // eslint-disable-next-line react/display-name
  //             action: key => (
  //               <Button
  //                 size='small'
  //                 variant='text'
  //                 color='inherit'
  //                 onClick={() => {
  //                   closeSnackbar(key)
  //                 }}
  //               >
  //                 Dismiss
  //               </Button>
  //             )
  //           }
  //         );
  //       } else {
  //         const message = unableMessage("details", "save")
  //         enqueueSnackbar(data?.message || message, { variant: ERROR });
  //       }
  //       setLastSaveSuccess(false)
  //       !skipMessage && setLoading(false);
  //     } else {
  //       handleSubmit(null)
  //       const message = validateMessage("required fields in General Info tab", "fill all")
  //       enqueueSnackbar(message, { variant: WARNING })
  //     }
  //   }
  //   return false;
  // }

  const checkJobDataUpdated = (oldContact, formValues, args) => {
    if ((oldContact.current_job_title !== formValues.current_job_title || oldContact.company?.id !== formValues.current_company?.id) && !skipCheckJobTitle) {
      setShowJobTitleChangedPopup(true)
      setHandleSaveArgs({ ...args })
      releaseLock();
      return { continue: false }
    }
    if (skipCheckJobTitle) {
      formValues.current_job_start_year = new Date().getFullYear()
      setSkipJobTitle(false)
      if (isUpdateEmploymentHistory) {
        let newJobHistory = [{
          company: oldContact.company,
          title: oldContact.current_job_title, start_year: oldContact.current_job_start_year, end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear()
        }]
        formValues.job_history = [...newJobHistory, ...oldContact.job_history]
        setEmploymentHistory(false)
      }
      setHandleSaveArgs({})
    }
    return { continue: true, formValues };
  }

  const handleSaveTabData = async (payload, saveAndClose, skipMessage, skipBack/* , tabValue */) => {
    const data = await acquire();
    if (data) {
      if (!payload) {
        if (saveAndClose) {
          if (!skipBack) {
            releaseLock();
            goBack()
            return true
          }
        }
        const message = notFoundMessage("changes")
        !skipMessage && !descriptionPopup && enqueueSnackbar(message, { variant: WARNING })
        return true
      }
      let jobHistoryYear = {}
      let boardYear = {}
      if(payload && payload.contact && payload.contact.job_history)
        jobHistoryYear = validateStartYearEndYear(payload.contact.job_history)

      if(payload && payload.contact && payload.contact.board_details)
        boardYear = validateStartYearEndYear(payload.contact.board_details)    
      if (payload.contact && payload.contact.updated_at) {
        payload.contact.updated_at = getUpdatedAt(payload.contact.updated_at, activityCurrentValues.contact.updated_at)
      }
      if (payload.candidate && payload.candidate.updated_at) {
        payload.candidate.updated_at = getUpdatedAt(payload.candidate.updated_at, activityCurrentValues.candidate.updated_at)
      }
      if ((jobHistoryYear && Object.keys(jobHistoryYear).length !== 0) || (boardYear && Object.keys(boardYear).length !== 0)) {
        enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
        return false
      }

      payload.activity = null;
      if (!skipMessage) {
        setStatusMessage('Updating details')
      }

      let type, param_id;
      if (contactId) {
        type = CONTACT
        param_id = contactId
      } else {
        type = CANDIDATE
        param_id = id
        // payload.candidate.updated_at = getUpdatedAt(currentValues.updated_at, activityCurrentValues.candidate.updated_at)
      }
      setLoading(true);

      const { status, data } = await candidateContactDataApi(POST, param_id, type, payload, ACTIVITIES);
      setStatusMessage("");

      if (status && (status === 201 || status === 200)) {

        setLastSaveSuccess(true)
        setRichTextSaved(false)
        if (!skipMessage) {
          const message = successMessage("Details", "updated",)
          enqueueSnackbar(message, { variant: SUCCESS })
        }
        if (payload.contact?.contact_profile_pic) {
          await uploadContactProfilePic(payload.contact.contact_profile_pic);
        }
        if (saveAndClose) {
          // let isActivitySaved = true;
          // if (activityPopupRef.current.isDirty()) {
          //   await getActivityLogInfo(skipMessage);
          //   isActivitySaved = await activityPopupRef.current.saveActivity()
          // }
          if (!skipBack) {
            releaseLock();
            goBack()
          } else {
            // await getActivityLogInfo();
          }
        } else {
          // await getActivityLogInfo(true);
        }
        setLoading(false);
        return true;
      } else if (status === 409) {
        const message = unableMessage("details", "save")
        enqueueSnackbar(data?.message || message,
          {
            persist: true,
            variant: ERROR,
            // eslint-disable-next-line react/display-name
            action: key => (
              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={() => {
                  closeSnackbar(key)
                }}
              >
                Dismiss
              </Button>
            )
          }
        );
      } else {
        const message = unableMessage("details", "save")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setLastSaveSuccess(false)
      !skipMessage && setLoading(false);
      // } else {
      //   // handleSubmit(null)
      //   const message = validateMessage("required fields in General Info tab", "fill all")
      //   enqueueSnackbar(message, { variant: WARNING })
      // }
    }
    return false;
  }

  useEffect(() => {
    const { saveAndClose, skipMessage, reload } = handleSaveArgs
    if (skipCheckJobTitle) {
      tabRefArray['GENERAL_INFO_TAB'].current.saveTabData(saveAndClose, skipMessage, reload)
    }
  }, [skipCheckJobTitle])


  useEffect(() => {
    let defaultValues = getRootDefaultValues(activityInf);
    if (defaultValues && isRichTextSaved) {
      defaultValues = { ...defaultValues, ...cleanupFormValues(getValues({ nest: true })) }
    }
    if (defaultValues) {
      setCurrentValues(defaultValues)
      reset(defaultValues)
      setParentUpdatedAt(defaultValues.candidateUpdatedAt)
    }
  }, [activityInf, isRichTextSaved, reset, getValues])

  const tabChange = async (tabValue, newValue) => {
    const isDirty = tabRefArray[currentTabName]?.current?.isDirty()
    console.log(currentTab, tabValue)
    let result = true;

    if (isDirty && !readOnly) {
      result = await tabRefArray[currentTabName]?.current?.saveTabData()
    }
    // }
    if (result) {
      setCurrentTab(newValue);
      const newTabName = getActivityLogTabNames(contactId, arenaSearch, includeLegacyTabs, newValue)
      setCurrentTabName(newTabName)
      setRichTextSaved(false)
      if (!readOnly && contactId && (newValue !== undefined || newValue !== null)) {
        history.push(`/contacts/${contactId}/log-an-activity/${newNavItems[newValue]}`, location.state, location.previousPath)
      }
      else if (!readOnly && !contactId && (newValue !== undefined || newValue !== null)) {
        history.push(`/searches/${searchId}/${tabName}/${id}/log-an-activity/${newNavItems[newValue]}`, location.state)
      }
      else if (readOnly && !contactId && (newValue !== undefined || newValue !== null)) {
        history.push(`/searches/${searchId}/${tabName}/${id}/log-an-activity/read-only/${newNavItems[newValue]}`, location.state)
      }
      else {
        history.push(`/contacts/${contactId}/log-an-activity/read-only/${newNavItems[newValue]}`, location.state, location.previousPath)
      }
    }
    return result;
    /*  if (dirty) {

      return handleSave(false, tabValue !== 0, undefined, undefined, tabValue)

    }

    await getActivityLogInfo() */
  }

  const handleBack = async () => {
    if (readOnly) {
      goBack()
      return;
    }
    setLoading(true);
    const isDirty = tabRefArray[currentTabName]?.current?.isDirty()
    if (isDirty) {
      setLoading(false);
      setShowConfirmBack(true)
    } else {
      releaseLock();
      goBack()
    }
    // const isDirty = generalInfoRef.current.isDirty()
    // const SKIP_TAB_ARRAY = [4, 7];
    // if ((/*(  || activityPopupRef.current.isDirty() &&}*/ !SKIP_TAB_ARRAY.includes(currentTab))) {
    //   setLoading(false);
    //   if(dirty || isDirty){
    //     setShowConfirmBack(true)
    //   }else  {
    //     releaseLock();
    //     goBack()
    //   }
    //   return;
    // } else {
    //   if (SKIP_TAB_ARRAY.includes(currentTab)) {
    //     releaseLock();
    //     await handleSave(false, false, false, true)
    //   }
    // }
    // releaseLock();
    // goBack()
  }
  useEffect(() => {
    return () => {
      dispatch(contactActions.resetCandidateDocList())
    };
  }, []);

  useEffect(() => {
    dispatch({ type: 'RESET_MILSTONE_PICKLIST' })
  }, [])

  useEffect(() => {
    return () => {
      dispatch(contactActions.resetCandidateDocList())
    };
  }, []);

  useEffect(() => {
    const isReadOnly = location.pathname.includes('read-only');
    setReadOnly(isReadOnly);
  }, [path,])

  const goBack = () => {
    const locationState = location.state;
    if (locationState && locationState.previousPath) {
      history.replace(locationState.previousPath, locationState)
      return
    }
    if (window.location.href.includes('all-candidates')) {
      clearCandidatesData();
      history.replace(`/searches/${searchId}/all-candidates`, locationState);
    } else if (window.location.href.includes('client-suite-report')) {
      clearCandidatesData();
      history.replace(`/searches/${searchId}/client-suite-report`, locationState);
    } else if (window.location.href.includes('my-linkedin-candidates')) {
      clearCandidatesData();
      history.replace(`/searches/${searchId}/my-linkedin-candidates`, locationState);
    } else if (window.location.href.includes('candidates')) {
      clearCandidatesData();
      history.replace(`/searches/${searchId}`, locationState);
    }
    else if (window.location.href.includes('contacts')) {
      history.replace(`/contacts/${contactId}`, locationState)
    }
    else {
      history.goBack();
    }
    dispatch({ type: 'resetLinkedinScraperEvents' })
  }

  const clearCandidatesData = () => {
    dispatch({ type: RELOAD_ALL_CANDIDATE_GRID })
  }

  const handleDocPopupClose = async () => {
    setShowDocs(false)
    descriptionPopup = false
    if (readOnly) return;
    // await getActivityLogInfo();
  }

  const handleShowHistory = () => {
    setShowActivityHistory(!showActivityHistory)
  }

  const handleShowDocs = async () => {
    if (readOnly) {
      setShowDocs(!showDocs)
      return;
    }
    descriptionPopup = true
    releaseLock();
    let result = true;
    const isDirty = tabRefArray[currentTabName]?.current?.isDirty()
    if (isDirty) {
      result = await tabRefArray[currentTabName]?.current?.saveTabData(false, false, true)
    }
    descriptionPopup = false
    // if(currentTab===0){
    //   const isDirty = generalInfoRef.current.isDirty()
    //   if(isDirty) {
    //     result = await generalInfoRef.current.saveTabData()
    //   }
    // }else {
    //   result = await handleSave(false, false, false, true)
    // }
    if (result) setShowDocs(!showDocs);
  }
  const handleWorkspacePopup = async (/* contact */) => {
    // if (!contact.private_email) {
    //   enqueueSnackbar(VALIDATION_MESSAGE.candidate_personal_email, { variant: ERROR });
    //   return false;
    // }
    // const email = EMAIL_REGEX.test(contact.private_email) ? contact.private_email : null;
    // if (!email) {
    //   const message = validationMessage("personal email")
    //   enqueueSnackbar(message, { variant: ERROR });
    //   return false;
    // }
    setLoading(true);
    const sub_route = `user-workspaces?userId=${userData.id}&searchId=${searchId}`
    let { status, data } = await hlDataApi(GET, sub_route);
    if (status && status === 200) {
      setLoading(false);
      if (data.workspaces.length > 0) {
        handleWorkspaceChange(data.workspaces);
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.hireLogic_workspace, { variant: ERROR });
      }
    } else {
      const message = unableMessage("Workspaces", "fetch")
      enqueueSnackbar(message, { variant: ERROR });
      setLoading(false);
    }
  };

  const handleWorkspaceChange = (value) => {
    setWorkspace(value);
  };

  const handleWorkspaceSubmit = async () => {
    setHireLogic(false);
    setLoading(true);
    const payload = {
      userId: userData.id,
      candidateId: id,
      searchId: searchId,
      workspaceId: workspace[0]?.id,
      contactId: contactId || activityInf?.contact.id
    };
    let { status, data } = await initializeSSO(payload);
    if (status && data && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.data.redirectLink;
      }
      const message = successMessage("Workspace", "saved")
      enqueueSnackbar(message, { variant: SUCCESS });
      setLoading(false);
    } else {
      let str = data.errorDetails.errors[0].title;
      if (data.status === 422) str = str.validationMessages.details[0].message;
      enqueueSnackbar(str, { variant: ERROR });
      setLoading(false);
    }
  };


  const useStylesHireLogic = makeStyles(() => ({
    arrow: { color: "white" },
    tooltip: {
      backgroundColor: "#dcdcdc",
      color: "slateblue",
      fontSize: 14,
      fontFamily: "Arial, sans-serif;",
      fontWeight: "initial",
      border: "1px solid lightgrey",
    },
  }));

  const HireLogicTooltip = (props) => {
    const classes = useStylesHireLogic();
    return <Tooltip arrow classes={classes} {...props} />;
  }

  const renderHeader = () => {
    const contact = activityInf.contact || {};
    return <div ref={logAnActivityRef} className="activity-log-header d-flex align-items-center">
      <CloseIcon color="primary" className="cursor-pointer" onClick={handleBack} />
      <Typography className="activity-log-text" variant="inherit">Activity Log</Typography>
      <Typography className="mr-1 ml-1" color="primary">|</Typography>
      <Typography variant="inherit">{`${contact.first_name || ''} ${contact.last_name || ''} ${contact.additional_last_name || ''}` || '--'}</Typography>
      <Typography className="mr-1 ml-1" color="primary">|</Typography>
      <Typography variant="inherit" color="primary" className="mr-3">{contact?.company?.name || ''}</Typography>
      <div className="d-flex align-items-center">
        {LinkedInRenderer(contact.linkedin_url, 'default')}
        <PhoneView contact={contact} increaseFontSize={true} />
        <EmailView contact={contact} increaseFontSize={true} />
        <DescriptionIcon fontSize="default" className="contact-profile-link" onClick={handleShowDocs} />
        <VideoView video={video} />
        <DirectionsRunIcon
          fontSize="default" className="contact-profile-link"
          onClick={handleShowHistory}
        />
        {!contactId && HIRELOGIC_STAGES.includes(activityInf.candidate.stage) ? (
          <HireLogicTooltip title="HireLogic Interview">
            <img
              src={HireLogicLogo}
              alt="Hire Logic"
              style={{ width: `18px`, cursor: 'pointer' }}
              onClick={() => {
                handleWorkspacePopup(activityInf.contact);
              }}
            />
          </HireLogicTooltip>
        ) : null}
        {hireLogic && (
          <HireLogic
            company={search?.company?.name}
            jobTitle={search?.job_title}
            handleWorkspaceSubmit={handleWorkspaceSubmit}
            handleWorkspaceChange={handleWorkspaceChange}
            workspace={workspace}
            options={options}
            onClose={() => {
              setHireLogic(false);
            }}
          />
        )}
      </div>
      {readOnly && <Typography variant="inherit" color="primary" className="ml-3 mr-3">Read Only</Typography>}
      <LogAnActivityPopup
        ref={activityPopupRef}
        parentUpdatedAt={parentUpdatedAt}
        arenaSearch={arenaSearch}
        target={logAnActivityRef}
        handleShowHistory={handleShowHistory}
        setLoading={setLoading}
        setStatusMessage={setStatusMessage}
        setActivityCurrentValues={setActivityCurrentValues}
        activityCurrentValues={activityCurrentValues}
        getActivityLogInfo={getActivityLogInfo}
        readOnly={readOnly}
      />
    </div>
  }

  const handleClose = () => {
    setShowConfirmBack(false)
  }

  const handleConfirm = async () => {
    setShowConfirmBack(false)
    let detailsSaved = true/* , activitySaved = true */;
    // let isDirty = generalInfoRef.current.isDirty;
    // if(currentTab === 0 && isDirty){
    //   detailsSaved = await generalInfoRef.current.saveTabData()
    // }else if (dirty) {
    //   detailsSaved = await handleSave(false, false, false, true)
    // }
    const isDirty = tabRefArray[currentTabName]?.current?.isDirty()
    if (isDirty) {
      detailsSaved = await tabRefArray[currentTabName]?.current?.saveTabData()
    }
    // if (activityPopupRef && activityPopupRef.current && activityPopupRef.current.isDirty()) {
    //   activitySaved = await activityPopupRef.current.saveActivity()
    // }
    if (/* activitySaved &&  */detailsSaved) {
      releaseLock()
      goBack();
    }
  }

  const saveStatusSetter = (value) => {
    setLastSaveSuccess(value)
  }

  const handleConfirmJobTitlePopup = async () => {
    setEmploymentHistory(true)
    setShowJobTitleChangedPopup(false)
    setSkipJobTitle(true)
  }

  const handleCancelJobTitlePopup = async () => {
    setEmploymentHistory(false)
    setShowJobTitleChangedPopup(false)
    setSkipJobTitle(true)
  }

  const getActivityLogInfoTabData = useCallback(async (hideLoader, tabName) => {
    !hideLoader && setLoading(true);
    let type, param_id, sub_route;
    //Activity Log for contacts
    if (contactId) {
      type = CONTACT
      param_id = contactId

    } else if (path === "candidates" || path === "all-candidates" || path === "client-suite-report" || path === "my-linkedin-candidates") {
      type = CANDIDATE
      param_id = id
    }
    sub_route = `${ACTIVITIES_TABDATA}?tabName=${tabName}&type=${type}`
    const { status, data } = await activityInfoApi(GET, param_id, sub_route);
    if (status && status === 200) {
      !hideLoader && setLoading(false);
      return data;
    } else {
      const message = unableMessage("activity info", "fetch")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      !hideLoader && setLoading(false);
      return null
    }
  }, [contactId, id, path, enqueueSnackbar])

  const getContactDetails = () => {
    return tabRefArray['GENERAL_INFO_TAB'].current.getContact()
  }
  const handleDiscard = async () => {
    if (!readOnly) {
      await releaseLock()
    }
    goBack()
  }

  return (
    <React.Fragment>
      {showActivityHistory &&
        <ActivityHistories
          setActivityCurrentValues={setActivityCurrentValues}
          contact={{
            id: activityInf.contact.id,
            first_name: activityInf.contact.first_name,
            last_name: activityInf.contact.last_name,
            additional_last_name: activityInf.contact.additional_last_name,
            current_job_title: activityInf.contact.current_job_title,
            company: activityInf.contact.company
          }}
          onPopupClose={() => {
            setShowActivityHistory(false)
          }}
          readOnly={readOnly}
        />
      }
      {showJobTitleChangedPopup &&
        <EmploymentHistoryConfirmationDialog
          header='Update'
          employmentHistoryMessageEndYearDate={employmentHistoryMessageEndYearDate}
          setEmploymentHistoryEndYearDate={setEmploymentHistoryEndYearDate}
          open={showJobTitleChangedPopup}
          contact={getContactDetails()}
          handleSubmit={handleConfirmJobTitlePopup}
          handleClose={handleCancelJobTitlePopup}
          cancelText='No'
          confirmText='Yes'
          extraClassName="jobhistory-popup"
        />
      }
      {showDocs && <DocsPopup contact={{
        id: activityInf.contact.id,
        first_name: activityInf.contact.first_name,
        last_name: activityInf.contact.last_name
      }} onPopupClose={handleDocPopupClose} disabled={readOnly} />}

      <MessageLoader show={loading} message={statusMessage} />
      {showConfirmBack &&
        <ConfirmationPopup
          header={VALIDATION_MESSAGE.unsave_changes_message}
          onConfirm={handleConfirm}
          onCancel={handleDiscard}
          onClose={handleClose}
          cancelText='Discard'
          confirmText='Save'
        />
      }
      {currentValues &&
        <Helmet>
          <title>Activity Log
            - {`${activityInf.contact.first_name || ''} ${activityInf.contact.last_name || ''}` || '--'}</title>
        </Helmet>
      }
      {currentValues ?
        <TabLayoutWithHeader
          grid={true}
          tabChange={tabChange}
          headerLayout={renderHeader()}
          navItems={[...NAV_ITEMS]}
          contactId={id}
          currentTab={currentTabIndex}
          actions={readOnly ? [{ label: 'Close', className: 'primary-btn', onClick: () => goBack() }] : []
          /*  (contactId ? (currentTab ===  0 ? [] : [
          actions={readOnly ? [{ label: 'Close', className: 'primary-btn', onClick: () => goBack() }] : (contactId ? [
            { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => handleSave(true) },
            { label: 'Save', className: 'primary-btn', onClick: () => handleSave(false) }
          ]) : HIDE_SAVE_BUTTON_ARRAY.includes(currentTab) ? [] : [
            // { label: 'Send Search Document', className: 'secondary-btn' },
            { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => handleSave(true) },
            { label: 'Save', className: 'primary-btn', onClick: () => handleSave(false) }
          ])*/}
          classList={readOnly ? 'read-only' : ''}
        >
          <GeneralInfo
            candidateId={id}
            isContactActivityLog={contactId}
            saveStatus={isLastSaveSuccess}
            ref={tabRefArray['GENERAL_INFO_TAB']}
            checkJobDataUpdated={checkJobDataUpdated}
            handleSaveTabData={handleSaveTabData}
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            readOnly={readOnly}
            isRichTextSaved={isRichTextSaved}
            setRichTextSaved={setRichTextSaved}
            getActivityLogInfo={getActivityLogInfo}
            saveStatusSetter={saveStatusSetter}
            setParentUpdatedAt={setParentUpdatedAt}
          />
          {
            !contactId && <MilestoneDates
              handleSaveTabData={handleSaveTabData}
              getActivityLogInfoTabData={getActivityLogInfoTabData}
              ref={tabRefArray['MILESTON_DATES_TAB']}
              readOnly={readOnly}
              setParentUpdatedAt={setParentUpdatedAt}
            />
          }
          <ScreeningNotesAndCompensation
            updateContactInfo={updateContactInfo}
            saveStatus={isLastSaveSuccess}
            autoSaveContactFields={autoSaveContactFields}
            handleSaveTabData={handleSaveTabData}
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['SCREENING_NOTES_TAB']}
            readOnly={readOnly}
            isRichTextSaved={isRichTextSaved}
            userdata={userData}
          />
          <EmploymentHistory
            handleSaveTabData={handleSaveTabData}
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['EMPLOYMENT_HISTORY_TAB']}
            readOnly={readOnly}
          />
          {
            !contactId && <Skills
              updateCandidateInfo={updateCandidateInfo}
              saveStatus={isLastSaveSuccess}
              setLoading={setLoading}
              acquire={acquire}
              releaseLock={releaseLock}
              goBack={goBack}
              readOnly={readOnly}
              getActivityLogInfoTabData={getActivityLogInfoTabData}
              ref={tabRefArray['EXPERIENCE_TAB']}
              setParentUpdatedAt={setParentUpdatedAt}
            />
          }
          {/* {!contactId && includeLegacyTabs && <LegacySkills
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['LEGASY_EXPERIENCE_TAB']}
            readOnly={readOnly}
            handleBack={handleBack}
          />} */}
          <StrengthsAndPotentialIssues
            setLoading={setLoading}
            autoSaveContactFields={autoSaveContactFields}
            updateContactInfo={updateContactInfo}
            saveStatus={isLastSaveSuccess}
            handleSaveTabData={handleSaveTabData}
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['STRENGTH_AND_ISSUE_TAB']}
            readOnly={readOnly}
            isRichTextSaved={isRichTextSaved}
          />
          {
            !contactId &&
            <Invites
              handleSaveTabData={handleSaveTabData}
              getActivityLogInfoTabData={getActivityLogInfoTabData}
              ref={tabRefArray['INVITES_TAB']}
              readOnly={readOnly}
              handleBack={handleBack}
            />
          }
          {
            !contactId && <Attributes
              acquire={acquire}
              releaseLock={releaseLock}
              goBack={goBack}
              saveStatus={isLastSaveSuccess}
              saveStatusSetter={saveStatusSetter}
              readOnly={readOnly}
              handleSaveTabData={handleSaveTabData}
              getActivityLogInfoTabData={getActivityLogInfoTabData}
              ref={tabRefArray['LEADERSHIP_COMPETENCIES_TAB']}
            />
          }
          {/* {!contactId && includeLegacyTabs && <LegacyAttributes
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['LEGASY_ATTRIBUTES_TAB']}
            readOnly={readOnly}
            handleBack={handleBack}
          />} */}
          <CandidateSnapshot
            updateContactInfo={updateContactInfo}
            saveStatus={isLastSaveSuccess}
            autoSaveContactFields={autoSaveContactFields}
            handleSaveTabData={handleSaveTabData}
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['CANDIDATE_SNAPSHOT_TAB']}
            readOnly={readOnly}
            isRichTextSaved={isRichTextSaved}
          />
          <Recommendations
            updateContactInfo={updateContactInfo}
            saveStatus={isLastSaveSuccess}
            autoSaveContactFields={autoSaveContactFields}
            handleSaveTabData={handleSaveTabData}
            getActivityLogInfoTabData={getActivityLogInfoTabData}
            ref={tabRefArray['RECOMMENDATION_TAB']}
            readOnly={readOnly}
          />
          {!contactId && <ClientSuiteFeedback
            candidateId={id} setLoading={setLoading}
            ref={tabRefArray['CLIENTSUITE_FEEDBACK']}
            readOnly={readOnly}
            handleBack={handleBack}
          />}
          {!contactId && <CandidateSuiteFeedback
            candidateId={id} setLoading={setLoading}
            ref={tabRefArray['CANDIDATESUITE_FEEDBACK']}
            readOnly={readOnly}
            handleBack={handleBack}
          />}
          {!contactId && arenaSearch && <ArenaFeedback
            candidateId={id}
            setLoading={setLoading}
            ref={tabRefArray['ARENA_FEEDBACK_TAB']}
            readOnly={readOnly}
            handleBack={handleBack}
          />}
          <References
            contactId={contactId || activityInf?.contact.id}
            contact={activityInf.contact}
            readOnly={readOnly}
            ref={tabRefArray['REFERENCES_TAB']}
            handleBack={handleBack}
          />
        </TabLayoutWithHeader > : ''}
    </React.Fragment >
  )
}

export default ActivityLog;
