
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { CircularProgress, Typography, Button } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ActionsPopover from "./ActionsPopover";
import PhonePopover from "./PhonePopover";
import NamePopover from "./NamePopover";
import {
  getMultiValueParam,
  updateContact,
  updateWorkbench,
  getDateFilterOptions,
  getFilterQuery,
  getList,
  DateRenderer
} from "../Commons/Utils";
import CountrySelection from "../../components/CountrySelection";
import StateSelection from "../../components/StateSelection"
import * as AttachmentsService from "../../services/AttachmentsService";
import IndustrySelection from "../../components/IndustrySelection";
import CompanySelection from "../../components/CompanySelection";
import JobFunctionSelection from "../../components/jobFunctionSelection";
import UserSelection from "../../components/UserSelection";
import InputField from "../../components/common/InputField";
import ColorSelection from "../../components/ColorSelection";
import OffLimitsSelection from "../../components/OffLimitsSelection";
import DiversityCandidateSelection from "../../components/DiversityCandidateSelection";
import GdprReleaseSelection from "../../components/GdprReleaseSelection";
import ExcludeFromClientSuite from "../../components/ExcludeFromClientSuite";
import {
  PUT,
  WARNING,
  ERROR,
  SUCCESS,
  EMAIL_REGEX,
  GET,
  LINKEDIN_SCRAPER,
} from "../../services/constantService";
import {
  acquireLockApi,
  contactDataApi,
  releaseLockApi,
} from "../../services/ApiService";
import {
  unableMessage,
  VALIDATION_MESSAGE,
  successMessage,
  validateMessage,
  notFoundMessage,
  requireMessage,
} from "../../services/MessageService";
import { formatDate, getDifferenceTime } from "../../utils/date";
import { tooltipValueGrid } from "../../utils/common";
// import CandidatePrioritySelection from "../../components/CandidatePrioritySelection";
import GenderSelection from "../../components/GenderSelection";

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

const getLastActivityType = (params) =>
  params.data?.contact_activities ? params.data?.contact_activities[0]?.type : null;
const getLastActivityDate = (params) =>
  params.data?.contact_activities ? params.data?.contact_activities[0]?.updated_at : null;
const getLastActivityUser = (params) =>
  params.data?.contact_activities ? params.data?.contact_activities[0]?.created_user.name : null;
const getName = (params) => (params.value ? params.value.name : "");
const getNotes = (params) =>
  params.data &&
  params.data.contact_note &&
  params.data.contact_note[0].contact_note;
const getUsers = (params) => {
  const name = [];
  if (params && params.data) {
    if (
      params &&
      params.data &&
      params.data.kg &&
      Array.isArray(params.data.kg)
    ) {
      params.data.kg.forEach((item) => {
        if (item.name) {
          name.push(item.name);
        } else {
          let string = `${item.first_name ? item.first_name : ""} ${item.last_name ? item.last_name : ""
            }`;
          name.push(string);
        }
      });
    }
  }
  return name.join(", ");
};
const getCreatedUser = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  return params.value ? params.value.first_name + " " + params.value.last_name : "";
}
const getIndustries = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  if (
    params.data &&
    params.data.industries &&
    Array.isArray(params.data.industries)
  ) {
    return params.data.industries.join(", ");
  }
  return "";
};
const stateSelection = (params) => {
  const country = params.data.country ? params.data.country : null
  return StateSelection({ ...params, country: country })
}
const getEditable = (params) => {
  return (params?.data?.country) ? true : false
}
const getInternationaleExperience = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  if (
    params.data &&
    params.data.international_experience &&
    Array.isArray(params.data.international_experience)
  ) {
    return params.data.international_experience.join(", ");
  }
  return "";
};
const getJobFunctions = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  if (params.value) {
    return params.value.join(", ");
  }
  return "";
};

const getColumnValue = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  if (params.value) {
    return params.data.country + "/" + params.data.city + "/" + params.data.state
  }
}
const getGender = (params) => {
  if (params && params.data && params.data.gender) {
    return params.data.gender
  }
}
const getEmailValue = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  if (params.value) {
    return params.data.work_email + "/" + params.data.private_email
  }
}

const getStatus = (params) => {
  if (params.data) {
    if (params.data && params.data.pdlStatus.length === 0) {
      return ' ';
    } else {
      if (params.data && params.data.pdlStatus[0].merged === true) {
        return `Enriched on ${formatDate(params.data.pdlStatus[0].updated_at)} By ${params.data.pdlStatus[0].fetched_user.first_name} ${params.data.pdlStatus[0].fetched_user.last_name}`;
      } else {
        return `Processing: Enrichment started on ${formatDate(params.data.pdlStatus[0].created_at)} By ${params.data.pdlStatus[0].fetched_user.first_name} ${params.data.pdlStatus[0].fetched_user.last_name}`;
      }
    }
  }
}

const getCellStyle = (params) => {
  if (params.data) {
    if (params.data && params.data.pdlStatus.length === 0) {
      return
    } else {
      if (params.data && params.data.pdlStatus[0].merged === true) {
        return { color: 'green' }
      } else {
        return { color: 'red' }
      }
    }
  } else {
    return
  }
}

export const cleanupQuery = (queryData) => {
  if (queryData.currentCompany) {
    queryData.currentCompany = queryData.currentCompany.map((item) => item.id);
  }
  if (queryData.title) {
    queryData.title = queryData.title.map((item) => item.id);
  }
  if (queryData.previousCompany) {
    queryData.previousCompany = queryData.previousCompany.map(
      (item) => item.id
    );
  }
  if (queryData.country) {
    queryData.country = queryData.country.map((item) => item.name);
  }
  if (queryData.industry) {
    queryData.industry = queryData.industry.map((item) => item);
  }
  if (queryData.bdTarget) {
    queryData.bdTarget = queryData.bdTarget.map((item) => item.id);
  }
  if (queryData.createdBy) {
    queryData.createdBy = queryData.createdBy.map((item) => item.id);
  }
  if (queryData.mailing_list) {
    queryData.mailing_list = queryData.mailing_list.map((item) => item.id);
  }
  if (queryData.jobFunction) {
    queryData.jobFunction = queryData.jobFunction.map((jobFunction) => {
      return jobFunction === "R&D" ? "R%26D" : jobFunction;
    });
  }
  if (queryData.internationalExperience) {
    queryData.internationalExperience = queryData.internationalExperience.map(
      (item) => item.name
    );
  }
  if (queryData.kg) {
    queryData.kg = queryData.kg.map((item) => item.id);
  }
  return queryData;
};

export const checkContactFetchingStatus = (params) => {
  // return params.data?.contact ? (params?.data?.contact?.data_fetching_status === 'PROCESSING' ? 'PROCESSING...' : false)  : (params?.data?.data_fetching_status === 'PROCESSING' ? 'PROCESSING...' : false )
  const contact = params.data?.contact || params?.data
  if (contact?.data_fetching_status === LINKEDIN_SCRAPER.PROCESSING) {
    return LINKEDIN_SCRAPER.PROCESSING_LABEL
  } else if (contact?.data_fetching_status === LINKEDIN_SCRAPER.FAILED) {
    return LINKEDIN_SCRAPER.FAILED_LABEL;
  }
  return false
};

export const checkRecordCanDelete = (params, status) => {
  const contact = params.data?.contact || params?.data
  if (status === LINKEDIN_SCRAPER.PROCESSING_LABEL && contact?.created_at) {
    /* Get difference in minutes from current date of contact updated_at,
       if record status is still Processing more than 5 minutes user can delete the record
    */
    const updateDiff = getDifferenceTime(contact.created_at);
    if (updateDiff > 1) {
      return true;
    }
  }
  return false
}

export const columnDefs = (
  showActivityHistory,
  enqueueSnackbar,
  closeSnackbar,
  SetLoader,
  option,
  showFilter = true,
  setJobHistoryPopupStates,
) => {
  let columns = [
    {
      headerName: "",
      field: "check_box",
      colId: "check_box",
      maxWidth: 50,
      minWidth: 50,
      headerComponent: "HeaderCheckbox",
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: false,
      pinned: "left",
      lockPinned: true,
      lockPosition: true,
    },
    {
      headerName: "Name",
      valueGetter: (params) =>
        params.data && params.data.first_name + " " + params.data.last_name,
      field: "first_name",
      colId: "first_name",
      sortable: true,
      maxWidth: 200,
      minWidth: 50,
      cellRenderer: "NameRenderer",
      pinned: "left",
      lockPinned: true,
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "company",
      colId: "company",
      maxWidth: 200,
      minWidth: 50,
      headerName: "Current Company",
      cellRenderer: "CompanyNameRenderer",
      // sortable: false,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: CompanySelection,
        multiple: true,
      },
    },
    {
      field: "current_job_title",
      colId: "current_job_title",
      tooltipField: "current_job_title",
      headerName: "Current Job Title",
      maxWidth: 200,
      minWidth: 50,
      /* if enable to editable it must show an employment history confirmation popup */
      editable: true,
      valueSetter: async (params) => {
        if (!params.newValue) {
          const message = requireMessage('Current Job Title', "is");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
        const newValue = { ...params }
        setJobHistoryPopupStates({ newContactData: newValue })
        SetLoader(true)
        let returnResult = true;
        const { status, data } = await contactDataApi(GET, params.data.id)
        if (status === 200) {
          setJobHistoryPopupStates({ currentContact: data, showJobTitleChangedPopup: true })
        } else {
          const message = notFoundMessage('Contact')
          enqueueSnackbar(message, { variant: ERROR })
          returnResult = false;
        }
        SetLoader(false)
        return returnResult
      },
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "job_functions",
      colId: "job_functions",
      maxWidth: 200,
      minWidth: 50,
      headerName: "Functions",
      tooltipValueGetter: getJobFunctions,
      cellRenderer: getJobFunctions,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: JobFunctionSelection,
        multiple: true,
      },
    },
    {
      field: "country",
      colId: "country",
      tooltipField: "country",
      headerName: "Location",
      maxWidth: 200,
      minWidth: 50,
      editable: true,
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: CountrySelection,
        label: "",
        placeholder: "",
      },
      valueSetter: (params) => {
        params.newValue = params.newValue.name;
        const lable = "country";
        return acquire(
          params,
          lable,
          enqueueSnackbar,
          closeSnackbar,
          true,
          SetLoader
        );
      },
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: CountrySelection,
        multiple: true,
      },
    },
    {
      field: "city",
      colId: "city",
      maxWidth: 200,
      minWidth: 50,
      tooltipField: "city",
      headerName: "City",
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "state",
      colId: "state",
      maxWidth: 200,
      minWidth: 50,
      tooltipField: "state",
      headerName: "State",
      editable: getEditable,
      filter: false, //showFilter & & "CustomFilter",
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: stateSelection,
        label: "",
        placeholder: "",
      },
      valueSetter: (params) => {
        params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
        if (params.oldValue === params.newValue || params.newValue === undefined) {
          return false;
        }
        const lable = "state";
        return acquire(
          params,
          lable,
          enqueueSnackbar,
          closeSnackbar,
          false,
          SetLoader
        );
      },
      filterParams: {
        Component: stateSelection,
        // filterOptions: ["contains"],
        // buttons: ["reset", "apply"],
        // suppressAndOrCondition: true,
        // closeOnApply: true,
      },
    },
    {
      field: "linkedin_url",
      colId: "linkedin_url",
      headerName: "LinkedIn",
      // tooltipField: "linkedin_url",
      tooltipValueGetter: (params) => {
        return tooltipValueGrid(params)
      },
      maxWidth: 100,
      minWidth: 50,
      cellRenderer: "LinkedInRenderer",
      editable: true,
      sortable: false,
      valueSetter: (params) => {
        const label = "linkedin_url";
        acquire(params, label, enqueueSnackbar, closeSnackbar, false, SetLoader);
      },
    },
    {
      field: "contact_resumes",
      colId: "contact_resumes",
      headerName: "Resume",
      maxWidth: 100,
      minWidth: 50,
      cellRenderer: "ResumeRenderer",
      cellRendererParams: { enqueueSnackbar },
      hide: true,
    },
    {
      field: "created_user",
      colId: "created_user",
      headerName: "Added by",
      sortable: true,
      maxWidth: 200,
      minWidth: 50,
      tooltipValueGetter: getCreatedUser,
      hide: true,
      cellRenderer: getCreatedUser,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : [],
      },
    },
    {
      field: "bd_target",
      colId: "bd_target",
      headerName: "BD Target",
      maxWidth: 200,
      minWidth: 50,
      // sortable: false,
      tooltipValueGetter: getCreatedUser,
      cellRenderer: getCreatedUser,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: UserSelection,
        field: "id",
        option: option ? option : [],
      },
    },
    /* {
                field: "kg",
                colId: "kg",
                headerName: "KG 25",
                tooltipValueGetter: getUsers,
                cellRenderer: getUsers,
                maxWidth: 200,
                minWidth: 50,
                // sortable: false,
                editable: true,
                cellEditor: "GenericCellEditor",
                cellEditorParams: {
                  InputComponent: UserSelection,
                  label: "KG 25",
                  placeholder: "",
                  multiple: true,
                  option: option ? option : [],
                },
                valueSetter: (params) => {
                  const old = [];
                  const newValues = [];
                  const lable = "kg";
                  params.data.kg.map((item) => old.push(item.name));
                  params.newValue.map((item) => newValues.push(item));
                  params.oldValue = old;
                  params.newValue = newValues;
                  return acquire(
                    params,
                    lable,
                    enqueueSnackbar,
                    closeSnackbar,
                    false,
                    SetLoader
                  );
                },
                filter: showFilter && "CustomFilter",
                filterParams: {
                  Component: UserSelection,
                  multiple: true,
                  option: option ? option : [],
                },
              }, */
    {
      field: "updated_at",
      colId: "updated_at",
      headerName: "Updated On",
      tooltipValueGetter: DateRenderer,
      cellRenderer: DateRenderer,
      hide: true,
      maxWidth: 200,
      minWidth: 50,
      filter: showFilter && "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "mobile_phone",
      colId: "mobile_phone",
      headerName: "Phone Number",
      cellRenderer: "PhoneRenderer",
      hide: true,
      maxWidth: 300,
      minWidth: 200,
      editable: true,
      sortable: false,
      cellEditor: "PhoneEditor",
      valueSetter: (params) => {
        const lable = "mobile_phone";
        return acquire(
          params,
          lable,
          enqueueSnackbar,
          closeSnackbar,
          false,
          SetLoader
        );
      },
      /* filter: showFilter && "agNumberColumnFilter",
                                filterParams: {
                                  filterOptions: ["contains"],
                                  buttons: ["reset", "apply"],
                                  suppressAndOrCondition: true,
                                  closeOnApply: true,
                                }, */
    },
    {
      field: "work_email",
      colId: "work_email",
      headerName: "Work Email",
      tooltipField: "work_email",
      cellRenderer: "EmailRenderer",
      editable: true,
      maxWidth: 300,
      minWidth: 50,
      valueSetter: (params) => {
        if (params.oldValue === params.newValue || params.newValue === undefined) {
          return false;
        }
        const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
        if (params.newValue && !email) {
          const message = validateMessage("valid work email", "enter");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          const label = "work_email";
          return acquire(
            params,
            label,
            enqueueSnackbar,
            closeSnackbar,
            false,
            SetLoader
          );
        }
      },
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "private_email",
      colId: "private_email",
      headerName: "Personal Email",
      tooltipField: "private_email",
      cellRenderer: "EmailRenderer",
      editable: true,
      maxWidth: 300,
      minWidth: 50,
      valueSetter: (params) => {
        if (params.oldValue === params.newValue || params.newValue === undefined) {
          return false;
        }
        const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
        if (params.newValue && !email) {
          const message = validateMessage("valid personal email", "enter");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          const label = "private_email";
          return acquire(
            params,
            label,
            enqueueSnackbar,
            closeSnackbar,
            false,
            SetLoader
          );
        }
      },
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "",
      colId: "actions",
      headerName: "Actions",
      maxWidth: 130,
      minWidth: 130,
      tooltipField: "",
      cellRenderer: "ActionsRenderer",
      cellRendererParams: {
        showActivityHistory,
      },
      sortable: false,
    },
    {
      field: "",
      colId: "add_contact_to",
      maxWidth: 130,
      minWidth: 130,
      headerName: "Add Contact to",
      tooltipField: "",
      cellRenderer: "AddContactToRenderer",
      sortable: false,
    },
    {
      field: "",
      colId: "athena",
      headerName: "Athena",
      tooltipField: "",
      cellRenderer: "AthenaRenderer",
      sortable: false,
      maxWidth: 200,
      minWidth: 50,
    },
    {
      field: "base_salary",
      colId: "base_salary",
      headerName: "Base",
      cellRenderer: "CompensationRenderer",
      sortable: false,
      maxWidth: 200,
      minWidth: 150,
      // filter: showFilter && "agNumberColumnFilter",
      // filterParams: {
      //   filterOptions: ["lessThan", "greaterThan"],
      //   buttons: ["reset", "apply"],
      //   suppressAndOrCondition: true,
      //   closeOnApply: true,
      // },
    },
    {
      field: "gender",
      colId: "gender",
      headerName: "Gender",
      tooltipField: "gender",
      minWidth: 50,
      maxWidth: 150,
      sortable: true,
      editable: false,
      cellEditor: "GenericCellEditor",
      valueSetter: getGender,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: GenderSelection,
        multiple: false,
      },
    },
    {
      field: "actual_bonus_value",
      colId: "actual_bonus_value",
      headerName: "Bonus",
      cellRenderer: "CompensationRenderer",
      hide: true,
      sortable: false,
      maxWidth: 200,
      minWidth: 50,
      // filter: showFilter && "agNumberColumnFilter",
      // filterParams: {
      //   filterOptions: getMatchFilterOptions(),
      //   buttons: ["reset", "apply"],
      //   suppressAndOrCondition: true,
      //   closeOnApply: true,
      // },
    },
    {
      field: "equity",
      colId: "equity",
      headerName: "Equity",
      tooltipField: "equity",
      hide: true,
      sortable: false,
      maxWidth: 200,
      minWidth: 50,
      // filter: showFilter && "agNumberColumnFilter",
      // filterParams: {
      //   filterOptions: ["lessThan", "greaterThan"],
      //   buttons: ["reset", "apply"],
      //   suppressAndOrCondition: true,
      //   closeOnApply: true,
      // },
    },
    {
      field: "industries",
      colId: "industries",
      headerName: "Industries",
      tooltipValueGetter: getIndustries,
      cellRenderer: getIndustries,
      maxWidth: 200,
      minWidth: 50,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: IndustrySelection,
        isSearchIndustry: true,
        multiple: true,
      },
    },
    {
      field: "gdpr_release",
      colId: "gdpr_release",
      headerName: "GDPR Release",
      maxWidth: 150,
      minWidth: 50,
      cellRenderer: (params) => {
        const status = checkContactFetchingStatus(params)
        if (status) return status;
        return params.value ? "Yes" : "No"
      },
      filter: "CustomFilter",
      filterParams: {
        Component: GdprReleaseSelection,
        field: "id",
      },
    },
    {
      field: "diversity_candidate",
      colId: "diversity_candidate",
      headerName: "Diversity Candidate",
      maxWidth: 200,
      minWidth: 50,
      cellRenderer: (params) => {
        const status = checkContactFetchingStatus(params)
        if (status) return status;
        return params.value === true ? "Yes" : params.value === false ? "No" : ""
      },
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: DiversityCandidateSelection,
        field: "id",
      },
    },
    {
      field: "international_experience",
      colId: "international_experience",
      headerName: "International Experience",
      // tooltipField: "international_experience",
      filter: "CustomFilter",
      tooltipValueGetter: getInternationaleExperience,
      cellRenderer: getInternationaleExperience,
      maxWidth: 220,
      minWidth: 50,
      filterParams: {
        Component: CountrySelection,
        multiple: true,
      },
    },
    {
      field: "hide_in_client_suite",
      colId: "hide_in_client_suite",
      headerName: "Exclude from ClientSuite",
      maxWidth: 200,
      minWidth: 50,
      cellRenderer: (params) => {
        const status = checkContactFetchingStatus(params)
        if (status) return status;
        return params.value ? "Yes" : "No"
      },
      filter: "CustomFilter",
      filterParams: {
        Component: ExcludeFromClientSuite,
        field: "id",
      },
    },
    {
      field: "freeze_dropdown",
      colId: "freeze_dropdown",
      headerName: "",
      sortable: false,
      filter: false,
      minWidth: 44,
      maxWidth: 44,
      width: 44,
      pinned: "right",
      lockPinned: true,
    },
    {
      field: "source",
      tooltipField: 'source',
      colId: "source",
      headerName: "Source",
      minWidth: 50,
      maxWidth: 200,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
  ]

  columns.forEach(ele => {
    if (ele.field !== 'first_name' && ele.field !== 'linkedin_url' && ele.field !== 'freeze_dropdown' && ele.field !== 'check_box' && ele.field !== 'international_experience' && ele.field !== 'source' && ele.field !== 'state') {
      ele.edit = ele.editable
      ele.editable = (params) => {
        return checkContactFetchingStatus(params) ? false : ele.edit
      }
      if (!ele.cellRenderer) {
        ele.cellRenderer = (params) => {
          const status = checkContactFetchingStatus(params)
          return status ? status : params?.value
        }
      }
    }
  })




  // }
  return columns
};

export const columnMergeContactDefs = (
  enqueueSnackbar,
  closeSnackbar,
  SetLoader,
  setJobHistoryPopupStates
) => {
  let columns = [
    {
      headerName: "",
      field: "check_box",
      colId: "check_box",
      maxWidth: 50,
      minWidth: 50,
      headerComponent: "HeaderCheckbox",
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: false,
      pinned: "left",
      lockPinned: true,
      lockPosition: true,
    },
    {
      headerName: "Name",
      valueGetter: (params) =>
        params.data && params.data.first_name + " " + params.data.last_name,
      field: "first_name",
      colId: "first_name",
      sortable: false,
      maxWidth: 200,
      minWidth: 50,
      cellRenderer: "NameRenderer",
      pinned: "left",
      lockPinned: true
    },
    {
      field: "status",
      colId: "status",
      headerName: "Status",
      tooltipField: "status",
      sortable: false,
      tooltipValueGetter: getStatus,
      cellRenderer: getStatus,
      cellStyle: getCellStyle,
      maxWidth: 300,
      minWidth: 50,
    },
    {
      field: "company",
      colId: "company",
      maxWidth: 200,
      minWidth: 50,
      headerName: "Current Company",
      cellRenderer: "CompanyNameRenderer",
      sortable: false,
    },
    {
      field: "current_job_title",
      colId: "current_job_title",
      tooltipField: "current_job_title",
      headerName: "Current Job Title",
      maxWidth: 200,
      sortable: false,
      minWidth: 50,
      /* if enable to editable it must show an employment history confirmation popup */
      valueSetter: async (params) => {
        if (!params.newValue) {
          const message = requireMessage('Current Job Title', "is");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
        const newValue = { ...params }
        setJobHistoryPopupStates({ newContactData: newValue })
        SetLoader(true)
        let returnResult = true;
        const { status, data } = await contactDataApi(GET, params.data.id)
        if (status === 200) {
          setJobHistoryPopupStates({ currentContact: data, showJobTitleChangedPopup: true })
        } else {
          const message = notFoundMessage('Contact')
          enqueueSnackbar(message, { variant: ERROR })
          returnResult = false;
        }
        SetLoader(false)
        return returnResult
      }
    },
    {
      field: "job_functions",
      colId: "job_functions",
      maxWidth: 200,
      minWidth: 50,
      headerName: "Functions",
      sortable: false,
      tooltipValueGetter: getJobFunctions,
      cellRenderer: getJobFunctions
    },
    {
      field: "industries",
      colId: "industries",
      headerName: "Industries",
      sortable: false,
      tooltipValueGetter: getIndustries,
      cellRenderer: getIndustries,
      maxWidth: 200,
      minWidth: 50
    },
    {
      field: "city",
      colId: "city",
      maxWidth: 200,
      minWidth: 50,
      sortable: false,
      tooltipValueGetter: getColumnValue,
      cellRenderer: getColumnValue,
      headerName: "Location/City/State"
    },
    {
      field: "linkedin_url",
      colId: "linkedin_url",
      headerName: "LinkedIn",
      // tooltipField: "linkedin_url",
      tooltipValueGetter: (params) => {
        return tooltipValueGrid(params)
      },
      hide: true,
      editable: true,
      maxWidth: 100,
      minWidth: 50,
      cellRenderer: "LinkedInRenderer",
      sortable: false,
      valueSetter: (params) => {
        const lable = "linkedin_url";
        acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
      },
    },
    {
      field: "work_email",
      colId: "work_email",
      headerName: "Work/Personal Email",
      tooltipField: "work_email",
      sortable: false,
      tooltipValueGetter: getEmailValue,
      cellRenderer: getEmailValue,
      maxWidth: 300,
      minWidth: 50
    }
  ];

  columns.forEach(ele => {
    if (ele.field !== 'first_name' && ele.field !== 'linkedin_url' && ele.field !== 'freeze_dropdown' && ele.field !== 'check_box' && ele.field !== 'state') {
      ele.edit = ele.editable
      ele.editable = (params) => {
        return checkContactFetchingStatus(params) ? false : ele.edit
      }
      if (!ele.cellRenderer) {
        ele.cellRenderer = (params) => {
          const status = checkContactFetchingStatus(params)
          return status ? status : params?.value
        }
      }
    }
  })
  return columns;
};

export const columnWorkbenchDefs = (
  showActivityHistory,
  enqueueSnackbar,
  closeSnackbar,
  showFilter = true,
  workbenchId,
  SetLoader,
  option,
  setJobHistoryPopupStates
) => [
  {
    headerName: "",
    field: "check_box",
    colId: "check_box",
    maxWidth: 50,
    minWidth: 50,
    headerComponent: "HeaderCheckbox",
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: "left",
    lockPosition: true,
  },
  // set this field only for workbench contacts
  {
    field: "contact_color",
    colId: "contact_color",
    headerName: "Color",
    cellRenderer: "ColorRenderer",
    maxWidth: 100,
    minWidth: 100,
    pinned: "left",
    lockPinned: true,
    filter: "CustomFilter",
    filterParams: {
      Component: ColorSelection,
      field: "value",
    },
  },
  {
    headerName: "Name",
    valueGetter: (params) =>
      params.data && params.data.first_name + " " + params.data.last_name,
    field: "first_name",
    colId: "first_name",
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    tooltipField: "first_name",
    cellRenderer: "NameRenderer",
    pinned: "left",
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "company",
    colId: "company",
    maxWidth: 200,
    minWidth: 50,
    headerName: "Current Company",
    tooltipValueGetter: getName,
    cellRenderer: "CompanyNameRenderer",
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: CompanySelection,
      multiple: true,
    },
  },
  {
    field: "gender",
    colId: "gender",
    headerName: "Gender",
    tooltipField: "gender",
    minWidth: 50,
    maxWidth: 150,
    sortable: true,
    editable: false,
    cellEditor: "GenericCellEditor",
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: GenderSelection,
      multiple: false,
    },
  },
  {
    field: "current_job_title",
    colId: "current_job_title",
    tooltipField: "current_job_title",
    maxWidth: 200,
    minWidth: 50,
    headerName: "Current Job Title",
    editable: true,
    valueSetter: async (params) => {
      if (!params.newValue) {
        const message = requireMessage('Current Job Title', "is");
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
      const newValue = { ...params }
      setJobHistoryPopupStates({ newContactData: newValue })
      SetLoader(true)
      let returnResult = true;
      const { status, data } = await contactDataApi(GET, params.data.id)
      if (status === 200) {
        setJobHistoryPopupStates({ currentContact: data, showJobTitleChangedPopup: true })
      } else {
        const message = notFoundMessage('Contact');
        enqueueSnackbar(message, { variant: ERROR });
        returnResult = false;
      }
      SetLoader(false)
      return returnResult;
    },
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "job_functions",
    colId: "job_functions",
    headerName: "Functions",
    maxWidth: 200,
    minWidth: 50,
    tooltipValueGetter: getJobFunctions,
    cellRenderer: getJobFunctions,
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: JobFunctionSelection,
      multiple: true,
    },
  },
  {
    field: "country",
    colId: "country",
    tooltipField: "country",
    headerName: "Location",
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    cellEditor: "GenericCellEditor",
    cellEditorParams: {
      InputComponent: CountrySelection,
      label: "",
      placeholder: "",
    },
    valueSetter: (params) => {
      params.newValue = params.newValue.name;
      const lable = "country";
      return acquire(
        params,
        lable,
        enqueueSnackbar,
        closeSnackbar,
        true,
        SetLoader
      );
    },
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: CountrySelection,
      multiple: true,
    },
  },
  {
    field: "city",
    colId: "city",
    maxWidth: 200,
    minWidth: 50,
    tooltipField: "city",
    headerName: "City",
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "state",
    colId: "state",
    tooltipField: "state",
    headerName: "State",
    maxWidth: 200,
    minWidth: 50,
    editable: getEditable,
    cellEditor: "GenericCellEditor",
    cellEditorParams: {
      InputComponent: stateSelection,
      label: "",
      placeholder: "",
    },
    valueSetter: (params) => {
      params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
      if (params.oldValue === params.newValue || params.newValue === undefined) {
        return false;
      }
      const lable = "state";
      return acquire(
        params,
        lable,
        enqueueSnackbar,
        closeSnackbar,
        false,
        SetLoader
      );
    },
    filter: false, //showFilter && "CustomFilter",
    filterParams: {
      Component: StateSelection,
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "linkedin_url",
    colId: "linkedin_url",
    headerName: "LinkedIn",
    maxWidth: 200,
    minWidth: 50,
    // tooltipField: "linkedin_url",
    tooltipValueGetter: (params) => {
      return tooltipValueGrid(params)
    },
    cellRenderer: "LinkedInRenderer",
    editable: true,
    sortable: false,
    valueSetter: (params) => {
      const lable = "linkedin_url";
      acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
    },
  },
  {
    field: "contact_resumes",
    colId: "contact_resumes",
    headerName: "Resume",
    maxWidth: 120,
    minWidth: 50,
    cellRenderer: "ResumeRenderer",
    cellRendererParams: { enqueueSnackbar },
    hide: true,
  },
  {
    field: "created_user",
    colId: "created_user",
    headerName: "Added by",
    tooltipValueGetter: getCreatedUser,
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    cellRenderer: getCreatedUser,
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: UserSelection,
      multiple: true,
      option: option ? option : [],
    },
  },
  {
    field: "bd_target",
    colId: "bd_target",
    headerName: "BD Target",
    tooltipValueGetter: getCreatedUser,
    cellRenderer: getCreatedUser,
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: UserSelection,
      field: "id",
      option: option ? option : [],
    },
  },
  {
    field: "kg",
    colId: "kg_25",
    headerName: "KG 25",
    tooltipField: "kg_25",
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    sortable: true,
    cellEditor: "GenericCellEditor",
    cellEditorParams: {
      InputComponent: UserSelection,
      label: "KG 25",
      placeholder: "",
      multiple: true,
      option: option ? option : [],
    },
    valueSetter: (params) => {
      const old = [];
      const newValues = [];
      const lable = "kg";
      params.data.kg.map((item) => old.push(item.name));
      params.newValue.map((item) => newValues.push(item));
      params.oldValue = old;
      params.newValue = newValues;
      return acquire(
        params,
        lable,
        enqueueSnackbar,
        closeSnackbar,
        false,
        SetLoader
      );
    },
    cellRenderer: getUsers,
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: UserSelection,
      multiple: true,
      option: option ? option : [],
    },
  },
  {
    field: "notes",
    colId: "contact_note",
    tooltipField: "Notes",
    headerName: "Notes",
    editable: true,
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    valueGetter: getNotes,
    valueSetter: (params) => {
      const lable = "notes";
      const notes = "notes";
      return acquire(
        params,
        lable,
        enqueueSnackbar,
        closeSnackbar,
        false,
        SetLoader,
        notes,
        workbenchId
      );
    },
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
    cellRenderer: getNotes,
  },
  {
    field: "last_activity_type",
    colId: "last_activity_type",
    tooltipValueGetter: getLastActivityType,
    cellRenderer: getLastActivityType,
    headerName: "Last Activity Type",
    maxWidth: 200,
    minWidth: 50,
    tooltipField: "last_activity_type",
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
    sortable: true,
    hide: true,
  },
  {
    field: "last_activity_date",
    colId: "last_activity_date",
    tooltipValueGetter: getLastActivityDate,
    valueGetter: getLastActivityDate,
    cellRenderer: "DateTimeRenderer",
    maxWidth: 200,
    minWidth: 50,
    headerName: "Last Activity Date",
    tooltipField: "last_activity_date",
    hide: true,
    sortable: true,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "last_activity_user",
    colId: "last_activity_user",
    tooltipValueGetter: getLastActivityUser,
    cellRenderer: getLastActivityUser,
    maxWidth: 200,
    minWidth: 50,
    headerName: "Last Activity User",
    tooltipField: "last_activity_user",
    filter: showFilter && "agTextColumnFilter" && "CustomFilter",
    filterParams: {
      Component: UserSelection,
      field: "id",
      option: option ? option : [],
    },
    sortable: true,
    hide: true,
  },
  {
    field: "updated_at",
    colId: "updated_at",
    headerName: "Updated On",
    tooltipValueGetter: DateRenderer,
    cellRenderer: DateRenderer,
    maxWidth: 200,
    minWidth: 50,
    hide: true,
    filter: showFilter && "agDateColumnFilter",
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "mobile_phone",
    colId: "mobile_phone",
    headerName: "Phone Number",
    cellRenderer: "PhoneRenderer",
    maxWidth: 300,
    minWidth: 200,
    hide: true,
    sortable: false,
    editable: true,
    cellEditor: "PhoneEditor",
    valueSetter: (params) => {
      const lable = "mobile_phone";
      return acquire(
        params,
        lable,
        enqueueSnackbar,
        closeSnackbar,
        false,
        SetLoader
      );
    },
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "work_email",
    colId: "work_email",
    headerName: "Work Email",
    tooltipField: "work_email",
    cellRenderer: "EmailRenderer",
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    valueSetter: (params) => {
      if (
        params.oldValue === params.newValue ||
          params.newValue === undefined
      ) {
        return false;
      }
      const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
      if (params.newValue && !email) {
        const message = validateMessage("valid work email", "enter");
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        const label = "work_email";
        return acquire(
          params,
          label,
          enqueueSnackbar,
          closeSnackbar,
          false,
          SetLoader
        );
      }
    },
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "private_email",
    colId: "private_email",
    headerName: "Personal Email",
    tooltipField: "private_email",
    cellRenderer: "EmailRenderer",
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    valueSetter: (params) => {
      if (params.oldValue === params.newValue || params.newValue === undefined) {
        return false;
      }
      const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
      if (params.newValue && !email) {
        const message = validateMessage("valid personal email", "enter");
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        const label = "private_email";
        return acquire(
          params,
          label,
          enqueueSnackbar,
          closeSnackbar,
          false,
          SetLoader
        );
      }
    },
    filter: showFilter && "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "",
    colId: "actions",
    headerName: "Actions",
    maxWidth: 130,
    minWidth: 130,
    tooltipField: "",
    cellRenderer: "ActionsRenderer",
    cellRendererParams: {
      showActivityHistory,
    },
    sortable: false,
  },
  {
    field: "",
    colId: "add_contact_to",
    headerName: "Add Contact to",
    maxWidth: 135,
    minWidth: 135,
    tooltipField: "",
    cellRenderer: "AddContactToRenderer",
    sortable: false,
  },
  {
    field: "",
    colId: "athena",
    headerName: "Athena",
    tooltipField: "",
    sortable: false,
    cellRenderer: "AthenaRenderer",
    maxWidth: 200,
    minWidth: 50,
  },
  {
    field: 'base_salary',
    colId: "base",
    headerName: "Base",
    cellRenderer: "CompensationRenderer",
    maxWidth: 200,
    minWidth: 150,
    sortable: false,
  },
  {
    field: "actual_bonus_value",
    colId: "actual_bonus_value",
    headerName: "Bonus",
    sortable: false,
    cellRenderer: "CompensationRenderer",
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    // filter: showFilter && "agNumberColumnFilter",
    // filterParams: {
    //   filterOptions: getMatchFilterOptions(),
    //   buttons: ["reset", "apply"],
    //   suppressAndOrCondition: true,
    //   closeOnApply: true,
    // },
  },
  {
    field: "equity",
    colId: "equity",
    headerName: "Equity",
    tooltipField: "equity",
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    sortable: false,
    // filter: showFilter && 'agTextColumnFilter',
    // filterParams: {
    //   filterOptions: ['contains'],
    //   buttons: ['reset', 'apply'],
    //   suppressAndOrCondition: true,
    //   closeOnApply: true
    // }
  },
  {
    field: "industries",
    colId: "industries",
    headerName: "Industries",
    tooltipValueGetter: getIndustries,
    cellRenderer: getIndustries,
    maxWidth: 200,
    minWidth: 50,
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: IndustrySelection,
      multiple: true,
      isSearchIndustry: true,
    },
  },
  {
    field: "gdpr_release",
    colId: "gdpr_release",
    headerName: "GDPR Release",
    maxWidth: 100,
    minWidth: 50,
    cellRenderer: (params) => (params.value ? "Yes" : "No"),
    filter: "CustomFilter",
    filterParams: {
      Component: GdprReleaseSelection,
      field: "id",
    },
  },
  {
    field: "diversity_candidate",
    colId: "diversity_candidate",
    headerName: "Diversity Candidate",
    maxWidth: 100,
    minWidth: 50,
    cellRenderer: (params) => (params.value ? "Yes" : "No"),
    filter: showFilter && "CustomFilter",
    filterParams: {
      Component: DiversityCandidateSelection,
      field: "id",
    },
  },
  {
    field: "international_experience",
    colId: "international_experience",
    headerName: "International Experience",
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    filter: "CustomFilter",
    filterParams: {
      Component: CountrySelection,
      multiple: true,
    },
  },
  {
    field: "hide_in_client_suite",
    colId: "hide_in_client_suite",
    headerName: "Exclude from ClientSuite",
    maxWidth: 200,
    minWidth: 50,
    cellRenderer: (params) => (params.value ? "Yes" : "No"),
    filter: "CustomFilter",
    filterParams: {
      Component: ExcludeFromClientSuite,
      field: "id",
    },
  },
  {
    field: "source",
    tooltipField: 'source',
    colId: "source",
    headerName: "Source",
    minWidth: 50,
    maxWidth: 200,
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  {
    field: "freeze_dropdown",
    colId: "freeze_dropdown",
    headerName: "",
    sortable: false,
    filter: false,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    pinned: "right",
    lockPinned: true,
  },
];

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};

export const acquire = async (
  params,
  lable,
  enqueueSnackbar,
  closeSnackbar,
  required = false,
  SetLoader,
  notes,
  workbenchId,
  changeJobHistory = false
) => {
  const newValue = { ...params };
  SetLoader(true);
  let { status, data } = await acquireLockApi(params.data.id);
  if (status && status === 200 && data.message === undefined) {
    if (notes === "notes") {
      return updateWorkbench(
        newValue,
        lable,
        enqueueSnackbar,
        required,
        workbenchId,
        SetLoader
      );
    } else {
      return updateContact(
        newValue,
        lable,
        enqueueSnackbar,
        required,
        SetLoader,
        undefined,
        changeJobHistory
      );
    }
  } else {
    SetLoader(false);
    //if (data.isAdmin) {
    enqueueSnackbar(data.message, {
      variant: WARNING,
      // eslint-disable-next-line react/display-name
      action: (key) => (
        <>
          <Button
            size="small"
            variant="text"
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
              releaseLock(
                params.data.id,
                newValue,
                lable,
                enqueueSnackbar,
                closeSnackbar,
                required,
                SetLoader,
                notes,
                workbenchId,
                changeJobHistory
              );
            }}
          >
            Force Unlock
          </Button>
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={async () => {
              closeSnackbar(key)
            }}
          >
            CLOSE
          </Button>
        </>
      ),
    });
    return false;
    /* }
    else {
      enqueueSnackbar(data.message, { variant: WARNING });
      return false;
    } */
  }
};
const releaseLock = async (
  id,
  params,
  lable,
  enqueueSnackbar,
  closeSnackbar,
  required,
  SetLoader,
  notes,
  workbenchId,
  changeJobHistory
) => {
  await releaseLockApi(id);
  acquire(
    params,
    lable,
    enqueueSnackbar,
    closeSnackbar,
    required,
    SetLoader,
    notes,
    workbenchId,
    changeJobHistory
  );
};

const downloadAttachment = async (attachmentId, enqueueSnackbar) => {
  try {
    const url = await AttachmentsService.download(
      attachmentId,
      "contact",
      "resumes"
    );
    if (url) {
      window.open(url);
    } else {
      const message = unableMessage("Resume", "download");
      enqueueSnackbar(message, { variant: ERROR });
    }
  } catch (e) {
    console.log("Error found in downloadAttachment::", e);
  }
};

export const ResumeRenderer = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  return params.value && params.value.length ? (
    <Typography
      className="text-link"
      color={"primary"}
      onClick={() =>
        downloadAttachment(
          params.value[0].id,
          params.colDef?.cellRendererParams.enqueueSnackbar
        )
      }
    >
      Yes
    </Typography>
  ) : (
    "No"
  );
};

export const EmailRenderer = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  if (params.value) {
    return (
      <a
        href={`mailto:${params.value}`}
        target={"noopener"}
        className="action-label"
        style={{ color: params.data?.offlimit?.length ? "red" : "default" }}
      >
        {params.value}
      </a>
    );
  }
  return null;
};

export const ActionsRenderer = (params) => {
  const list = [
    {
      label: "Log An Activity",
      link: `/contacts/${params?.data?.id}/log-an-activity/general-info`,
    },
    {
      label: "Show History",
      onClick: params.colDef
        ? () =>
          params.colDef.cellRendererParams.showActivityHistory(params.data)
        : null,
    },
  ];
  return <ActionsPopover list={list} />;
};

export const PhoneRenderer = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  const phones = [];
  if (params.value) {
    phones.push({ label: "Work", value: params.value });
  }

  if (params.data && params.data.home_phone) {
    phones.push({ label: "Personal", value: params.data.home_phone });
  }

  if (phones.length > 0) {
    return (
      <PhonePopover phones={phones}>
        <a
          href={`tel:${phones[0].value}`}
          target={"noopener"}
          className="action-label"
        >
          {phones[0].value}
        </a>
      </PhonePopover>
    );
  }
  return <div />;
};

export const ContactNameRenderer = (props) => {
  const { params, handleAddToSearch } = props;

  const getName = (params) => {
    if (params.data) {
      if (params.data.contact) {
        const firstName = params.data.contact.first_name || "";
        const lastName = params.data.contact.last_name || "";
        const additionalLastName = params.data.contact.additional_last_name || "";
        return `${firstName} ${lastName} ${additionalLastName}`;
      }
      const firstName = params.data.first_name || "";
      const lastName = params.data.last_name || "";
      const additionalLastName = params.data.additional_last_name || "";
      return `${firstName} ${lastName} ${additionalLastName}`;
    }
    return "";
  };
  if (params.data) {
    return (
      <>
        {params.data.isFromGalaxy === false || (checkContactFetchingStatus(params)) ? <>{getName(params)}</> :
          <NamePopover
            contact={params.data.contact ? params.data.contact : params.data}
            handleAddToSearch={handleAddToSearch}
          >
            <Link
              to={`/contacts/${params.data.contact ? params.data.contact.id : params.data.id
                }/details`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                className="text-link text-capitalize"
                color={
                  params.data?.isFromGalaxy ? "primary" :
                    (params.data?.offlimit === true ||
                      params.data?.contact?.offlimit === true
                      ? "error"
                      : "primary")
                }
              >
                {getName(params)}
              </Typography>
            </Link>
          </NamePopover>}
      </>
    );
  }
  return null;
};

ContactNameRenderer.propTypes = {
  params: PropTypes.object,
  handleAddToSearch: PropTypes.func,
};

export const getQueryParamsString = (data) => {
  const name = getSingleValueParam(data, "name");
  const currentJobTitle = getSingleValueParam(data, "current_job_title");
  const city = getSingleValueParam(data, "city");
  const state = getSingleValueParam(data, "state");
  const fromCreate = data.fromCreate
    ? `&created_at_from=${data.fromCreate}`
    : "";
  const toCreate = data.toCreate ? `&created_at_to=${data.toCreate}` : "";
  const schoolName = getSingleValueParam(data, "schoolName");
  const previousJobTitle = getSingleValueParam(data, "previousJobTitle");
  const diversityCandidate = getSingleValueParam(data, "diversity_candidate");
  const currentCompany = getMultiValueParam(data.currentCompany, "company");
  const job_number = getMultiValueParam(data.title, "job_number");
  const kg = getMultiValueParam(data.kg, "kg");
  const jobFunction = getMultiValueParam(
    getList(data["jobFunction"], "name"),
    "job_functions"
  );
  const industry = getMultiValueParam(
    getList(data["industry"], "name"),
    "industry"
  );
  const country = getMultiValueParam(data.country, "country");
  const previousCompany = getMultiValueParam(
    data.previousCompany,
    "previousCompany"
  );
  const bdTarget = getMultiValueParam(data.bdTarget, "bdTarget");
  const createdBy = getMultiValueParam(data.createdBy, "created_by");
  const internationalExperience = getMultiValueParam(
    data.internationalExperience,
    "international_experience"
  );
  const mailingList = getMultiValueParam(data.mailing_list, "mailing_list");
  const gender = getSingleValueParam(data, "gender")
  return (
    name +
    currentJobTitle +
    diversityCandidate +
    city +
    state +
    fromCreate +
    toCreate +
    currentCompany +
    jobFunction +
    industry +
    country +
    previousCompany +
    schoolName +
    previousJobTitle +
    bdTarget +
    createdBy +
    internationalExperience +
    mailingList +
    kg +
    job_number +
    gender
  );
};

const getSingleValueParam = (data, param) => {
  return data[param] ? `&${param}=${data[param]}` : "";
};

export const addAsMyBDTarget = async (
  contact,
  user,
  gridApi,
  enqueueSnackbar
) => {
  if (contact.bd_target) {
    const message = `${VALIDATION_MESSAGE.bd_target} ${contact.bd_target.name}. Please contact ${contact.bd_target.name} for more info`
    enqueueSnackbar(message, { variant: WARNING });
    return;
  }

  gridApi.showLoadingOverlay();
  const payload = { bd_target_id: user?.id };
  const { status, data: res } = await contactDataApi(PUT, contact.id, payload);
  if (status === 200) {
    gridApi.hideOverlay();
    const message = successMessage("BD Target", "added as your");
    enqueueSnackbar(message, { variant: SUCCESS });
  } else {
    const message = unableMessage("contact details", "update");
    enqueueSnackbar(res.message || message, { variant: ERROR });
  }
  gridApi.hideOverlay();
};

const queryAliases = {
  first_name: "name",
  industries: "industry",
  created_user: "created_by",
  /* title: "job_number" */
};
const queryValueTypes = {
  job_functions: { field: "name" },
  location: { field: "name" },
  country: { field: "name" },
  industry: { field: "name" },
  international_experience: { field: "name" },
};

export const getFilterParamString = (data, prefix, AdvancedQuery) => {
  return getFilterQuery(
    data,
    queryAliases,
    queryValueTypes,
    prefix,
    AdvancedQuery
  );
};

export const defaultColumns = ["first_name", "freeze_dropdown"];

export const getSelectedContactIds = (gridApi) => {
  const contactIds = [];
  gridApi.forEachNode((node) => {
    if (node.selected) {
      contactIds.push(node.data.id); // Only for contact grids
    }
  });
  return contactIds;
};

const getFilterParam = (key, filter, queryAliases) => {
  if (filter.filterType === "date" || filter.filterType === "number") {
    return `${key}_${filter.type === "lessThan" ? "to" : "from"}`;
  }
  return queryAliases[key] || key;
};

export const getFilterQueryData = (
  data,
  queryAliases,
  queryValueTypes,
  prefix
) => {
  let queryData = {};
  Object.keys(data).forEach((key) => {
    const filterParam = getFilterParam(key, data[key], queryAliases);
    const filterKey = (prefix ? "sub_" : "") + filterParam;
    let queryKeyValue = data[key].filter;
    if (Array.isArray(queryKeyValue) && queryKeyValue.length) {
      if (!queryValueTypes[filterParam]?.isValueType) {
        queryKeyValue = getList(
          queryKeyValue,
          queryValueTypes[filterParam]?.field || "id"
        );
      }
    } else {
      if (data[key].filterType === "date") {
        const { dateFrom, dateTo } = data[key];
        queryKeyValue = dateFrom ? dateFrom : dateTo;
      }
    }
    if (queryKeyValue) {
      queryData[filterKey] = queryKeyValue;
    }
  });
  return queryData;
};

const getFormattedQueryData = (queryData) => {
  const data = {};
  const aliases = {
    fromCreate: "created_at_from",
    toCreate: "created_at_to",
    currentCompany: "company",
    jobFunction: "job_functions",
    createdBy: "created_by",
    internationalExperience: "international_experience",
  };

  Object.keys(queryData).forEach((key) => {
    data[aliases[key] || key] = queryData[key];
  });

  return data;
};

export const getQueryPayload = (queryData, filterModel) => {
  const filterData = getFilterQueryData(
    filterModel,
    queryAliases,
    queryValueTypes,
    true
  );
  const parsedQueryData = getFormattedQueryData(queryData);
  return { ...parsedQueryData, ...filterData };
};

const dateProps = {
  variant: "inline",
  format: "dd/MM/yyyy",
  placeholder: "Select",
  disableToolbar: false,
  autoOk: true,
};

export const fieldDefinitions = {
  "Contact Name": {
    component: InputField,
    field: "contact_full_name",
    props: { placeholder: "Enter" },
  },

  "Current Company": {
    component: InputField,
    field: "currentCompany.name",
    props: { placeholder: "Enter" },
  },

  "Job Title": {
    component: InputField,
    field: "current_job_title",
    props: { placeholder: "Enter" },
  },

  "Job Function": {
    component: JobFunctionSelection,
    field: "job_functions",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },

  Industry: {
    component: IndustrySelection,
    field: "industries",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },

  City: {
    component: InputField,
    field: "city",
    props: { placeholder: "Enter" },
  },

  Location: {
    component: CountrySelection,
    field: "country",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Search",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },

  State: {
    component: InputField,
    field: "state",
    props: { placeholder: "Enter" },
  },

  "School Name": {
    component: InputField,
    field: "contactEducation.school_name",
    props: { placeholder: "Enter" },
  },

  "Previous Company": {
    component: InputField,
    field: "JobHistory.company.name",
    props: { placeholder: "Enter" },
  },

  "Previous Job Title": {
    component: InputField,
    field: "JobHistory.title",
    props: { placeholder: "Enter" },
  },

  "Bd Target": {
    component: UserSelection,
    field: "bdTarget.id",
    type: "search",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Diversity Candidate": {
    component: OffLimitsSelection,
    field: "diversity_candidate",
    type: "selection",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Added By": {
    component: UserSelection,
    field: "createdUser.id",
    type: "search",
    props: {
      placeholder: "Search",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },

  "Created Date Range": {
    component: KeyboardDatePicker,
    field: "created_at",
    type: "dateRange",
    props: { ...dateProps },
  },

  "International Experience": {
    component: CountrySelection,
    field: "international_experience",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Source": {
    component: InputField,
    field: "source",
    props: { placeholder: "Enter" },
  },
  "Gender": {
    component: GenderSelection,
    field: "gender",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    }
  }
};
